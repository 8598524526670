import { render, staticRenderFns } from "./OrderStartReturn.vue?vue&type=template&id=72de284c"
import script from "./OrderStartReturn.vue?vue&type=script&lang=js"
export * from "./OrderStartReturn.vue?vue&type=script&lang=js"
import style0 from "./OrderStartReturn.vue?vue&type=style&index=0&id=72de284c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QForm,QCheckbox,QSelect,QInput,QBtn,QField,QColor});
