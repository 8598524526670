<template>
  <div class="quantityBox full-width">
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <span
        :class="['error text-center', 'top']"
        v-if="product"
        v-show="
          cartQty >= this.product.qtyAvailableforReturn &&
            (showMaxError || focus)
        "
      >
        Max {{ this.product.qtyAvailableforReturn }}
      </span>
    </transition>
    <template v-if="cartQty > 0 || focus">
      <q-btn
        round
        dense
        @click="addItemInCart(-1)"
        flat
        icon="remove"
        size="9px"
        :class="cartQty == 1 ? 'disable' : ''"
        :disable="findObject()"
      />
      <q-input
        @blur="
          () => {
            this.focus = false
            if (this.cartQty != this.product.qtyAvailableforReturn) {
              this.updateCart()
            }
          }
        "
        class="text-center qtyGroup"
        outlined
        v-mask="'####'"
        v-model="cartQty"
        dense
        @focus="focus = true"
        @keyup.enter="$event.target.blur()"
        :disable="findObject()"
      />
      <q-btn
        round
        dense
        @click="addItemInCart(+1)"
        flat
        icon="add"
        size="0.6rem"
        :class="cartQty == this.product.qtyAvailableforReturn ? 'disable' : ''"
        :disable="findObject()"
      />
    </template>
    <q-btn
      v-else-if="showSelectButton"
      color="primary"
      @click="addItemInCart(+1)"
      outline
      label="Select"
      class="full-width"
      :disable="findObject()"
    />
  </div>
</template>

<script>
export default {
  name: 'OrderCartButton',
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    updateReturnItemQty: {
      type: Function,
      default: () => {},
    },
    selection: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      quantity: null,
      showMaxError: false,
      showMaxErrorTimer: null,
      focus: false,
    }
  },
  computed: {
    qtyLimit() {
      return (this.product && this.product.qtyAvailableforReturn) || 9999
    },
    cartQty: {
      get() {
        if (this.quantity !== null) {
          return this.quantity
        }
        let info = this.product
        return parseInt(info.qtyAvailableforReturn || 0)
      },
      set(val) {
        this.quantity = val || 0
      },
    },
  },
  methods: {
    checkMaxLimit() {
      if (this.cartQty >= this.product.qtyAvailableforReturn) {
        this.showMaxError = true

        if (this.showMaxErrorTimer) {
          clearTimeout(this.showMaxErrorTimer)
          this.showMaxErrorTimer = null
        }

        this.showMaxErrorTimer = setTimeout(() => {
          this.showMaxError = false
        }, 2000)

        this.cartQty = this.product.qtyAvailableforReturn
      } else if (this.cartQty == 0) {
        this.cartQty = 1
      }
    },
    findObject() {
      return this.selection.filter(
        (x) => x.orderLineItemID === this.product.orderLineItemID
      ).length > 0
        ? false
        : true
    },
    addItemInCart(incrementQty) {
      let cartQty = parseInt(this.cartQty),
        timeout = cartQty > 1 ? 300 : 0

      incrementQty = parseInt(incrementQty)

      this.cartQty = cartQty + incrementQty > 0 ? cartQty + incrementQty : 0

      this.checkMaxLimit()

      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.updateCart()
      }, timeout)
    },
    updateCart(quantity) {
      this.checkMaxLimit()

      if (!quantity && quantity !== 0) {
        quantity = this.cartQty
      }

      let objItem = {
        orderLineItemID: this.product.orderLineItemID,
        qty: quantity,
      }
      this.updateReturnItemQty(objItem)
    },
  },
}
</script>
<style lang="scss">
.quantityBox {
  .error {
    left: 0;
    right: 0;
  }
}
</style>
