<template>
  <q-page class="accountPages orderStartReturn">
    <div class="container" v-if="orderInfo && orderInfo.orderNumber">
      <div class="page-title hide-in-mobile">
        <div class="page-breadcrumb">
          <a
            href="javascript:void(0)"
            @click="applyBack()"
            class="no-underline"
          >
            <SvgIcon icon="back-arrow-line" />Back
          </a>
        </div>
        <h2>Item Return Process</h2>
        <div class="row" v-if="!isMobile">
          <Alert
            v-if="startReturnAlert"
            className="col-12 col-md-6 q-mt-sm"
            :variant="startReturnAlert.variant"
            @dismissed="
              () => {
                this.alertShow = 0
              }
            "
            @dismiss-count-down="
              (countDown) => {
                this.alertShow = countDown
              }
            "
            :show="alertShow"
          >
            <span v-html="startReturnAlert.message" />
          </Alert>
        </div>
      </div>
      <div class="page-content">
        <!-- Order Return 1 -->
        <q-form @submit="postReason">
          <div class="orderTables curbside">
            <div class="orderItem orderItemhd">
              <div class="checkbox">
                <q-checkbox v-model="isCheckAll" @input="checkAll" />
              </div>
              <div class="orderItemDetail">
                <span class="icon">
                  <SvgIcon color="primary" :icon="orderInfo.codeName" />
                </span>
                <h5 v-html="orderInfo.displayName" />
              </div>
              <div class="OrdetItemIssue">Reason for Return</div>
              <div class="OrdetItemQty">Qty</div>
              <div class="OrdetItemTotal">Total</div>
            </div>
            <div
              v-for="item of orderInfo.orderLineItemsForReturn"
              :key="item.orderLineItemID"
              :class="[
                'orderItem orderItemrow',
                reason[item.orderLineItemID] === 'Other'
                  ? 'orderItemTextarea'
                  : '',
              ]"
            >
              <div class="orderItemDetail">
                <span class="checkbox">
                  <q-checkbox
                    v-model="selection"
                    :val="item"
                    :disable="!item.isReturnable"
                    @input="updateCheckall(item)"
                /></span>
                <div class="OrdetItemImg">
                  <box-image
                    :link="{
                      name: 'Detail',
                      params: { seoName: item.seoName },
                    }"
                    :imageSrc="item.image"
                  />
                </div>
                <router-link
                  class="no-underline"
                  :to="{
                    name: 'Detail',
                    params: { seoName: item.seoName },
                  }"
                  ><h5 v-html="item.productName"
                /></router-link>
              </div>
              <div class="OrdetItemIssue">
                <q-select
                  dense
                  outlined
                  v-model="reason[item.orderLineItemID]"
                  :options="
                    returnReason &&
                    returnReason.filter((reason) => {
                      if (reason.returnReasonID == 2) {
                        return !!item.isSubstitute
                      }
                      return true
                    })
                  "
                  option-value="returnReasonTitle"
                  option-label="returnReasonTitle"
                  emit-value
                  map-options
                  options-dense
                  class="issueSelectOption"
                  label="Select Reason"
                  :disable="findObject(item)"
                  @input="selectChange(item.orderLineItemID, item)"
                  :rules="[(val) => myRules(val, item)]"
                  :lazy-rules="lazyValidationRules"
                />
              </div>
              <div class="OrdetItemQty">
                <div class="row">
                  <div class="col-auto col-sm-auto col-md-auto">
                    <ReturnOrderCartButton
                      :selection="selection"
                      :product="item"
                      :updateReturnItemQty="updateReturnItemQty"
                    />
                  </div>
                  <div class="col-5 col-sm-6 offset-sm-0 col-md-6 text-left">
                    <span class="block q-pt-sm">
                      <span class="discount" v-if="item.discount">
                        You Saved:
                        {{ item.discount | currency(false) }}
                      </span>
                      {{ item.qty }} x {{ item.price | currency }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="OrdetItemTotal">{{ item.finalPrice | currency }}</div>
              <div
                class="form-action OrdetItemAction"
                style=""
                v-if="reason[item.orderLineItemID] === 'Other'"
              >
                <template
                  ><q-input
                    outlined
                    autogrow
                    @change="otherReasonChange(item.orderLineItemID)"
                    v-model.trim="otherReason[item.orderLineItemID]"
                    :disable="findObject(item)"
                    dense
                    type="textarea"
                    label="Please Enter Other Reason"
                    rows="1"
                    maxlength="3000"
                  />
                  <span
                    class="float-right text-caption"
                    v-text="
                      `${
                        otherReason[item.orderLineItemID]
                          ? Object.keys(otherReason[item.orderLineItemID])
                              .length
                          : 0
                      }/3000`
                    "
                  />
                </template>
              </div>
            </div>
          </div>
          <div class="orderSummaryInfo">
            <div class="row q-col-gutter-md">
              <div
                v-if="orderInfo.orderSummary"
                class="col-xs-12 col-sm-6 col-md-3 o-1"
              >
                <h5>Order Summary</h5>
                <ul class="orderSummaryITotal">
                  <li>
                    Sub Total
                    <span>
                      {{ orderInfo.orderSummary.subTotal | currency(true) }}
                    </span>
                  </li>

                  <template
                    v-if="
                      orderInfo.orderSummary.additionalCharges &&
                      orderInfo.orderSummary.additionalCharges.length
                    "
                  >
                    <li
                      v-for="(additionalCharge, index) of orderInfo.orderSummary
                        .additionalCharges"
                      :key="`${additionalCharge.chargeName}-${index}`"
                    >
                      {{ additionalCharge.chargeName }}
                      <span>
                        {{
                          additionalCharge.chargeAmount | currency(false, true)
                        }}
                      </span>
                    </li>
                  </template>

                  <li v-if="orderInfo.orderSummary.taxes">
                    Tax
                    <span>
                      {{ orderInfo.orderSummary.taxes | currency(false, true) }}
                    </span>
                  </li>
                  <li v-if="orderInfo.orderSummary.discount">
                    Discount Applied
                    <span>
                      {{ orderInfo.orderSummary.discount | currency(false) }}
                    </span>
                  </li>
                  <li class="total">
                    Order Total
                    <span>
                      {{ orderInfo.orderSummary.cartTotal | currency }}
                    </span>
                  </li>
                </ul>
              </div>
              <div
                v-show="!isMobile"
                v-if="orderInfo.pickupStore"
                class="col-xs-12 col-sm-6 col-md-3 o-2"
              >
                <h5>Pickup Store</h5>
                <p>
                  {{ orderInfo.pickupStore.name }}
                  <br />
                  <span
                    v-html="
                      $options.filters.formatAddress(orderInfo.pickupStore)
                    "
                  />
                </p>
              </div>
              <div
                v-show="!isMobile"
                v-if="orderInfo.pickupPerson"
                class="col-xs-12 col-sm-6 col-md-3 o-3"
              >
                <h5>Pickuped by</h5>
                <p>
                  Name: {{ orderInfo.pickupPerson.pickUpByName1 }}<br />
                  <!-- Phone:
                  {{
                    orderInfo.pickupPerson.pickUpByPhone1
                      ? maskPhoneNumber(orderInfo.pickupPerson.pickUpByPhone1)
                      : 'NA'
                  }} -->
                  Phone:
                  {{
                    orderInfo.pickupPerson.pickUpByEmail1
                      ? orderInfo.pickupPerson.pickUpByEmail1
                      : 'NA'
                  }}
                </p>
              </div>
              <div
                v-show="!isMobile"
                v-if="orderInfo.orderPaymentDetail"
                class="col-xs-12 col-sm-6 col-md-3 o-4"
              >
                <h5>Payment Method</h5>
                <p>
                  Credit Card<br />
                  <span :class="`paymentIcon paymentIcon-left`">
                    <img
                      :src="`images/payment/${
                        getCardType(orderInfo.orderPaymentDetail.cardType).logo
                      }`"
                      :title="
                        getCardType(orderInfo.orderPaymentDetail.cardType).title
                      "
                    />
                  </span>
                  <!-- <SvgIcon size="24px" icon="card" class="q-mr-sm" /> -->
                  xxxx-xxxx-xxxx-{{
                    orderInfo.orderPaymentDetail.cardLast4Digits
                  }}
                </p>
              </div>
            </div>
          </div>
          <div v-show="isMobile" class="orderReturnMobile">
            <div v-if="orderInfo.pickupStore">
              <div class="orderReturnMobinfo">
                <h5>Pickup Store</h5>
                <p>
                  {{ orderInfo.pickupStore.name }}
                  <br />
                  <span
                    v-html="
                      $options.filters.formatAddress(orderInfo.pickupStore)
                    "
                  />
                </p>
              </div>
            </div>
            <div v-if="orderInfo.pickupPerson">
              <div class="orderReturnMobinfo">
                <h5>Pickuped by</h5>
                <p>
                  Name: {{ orderInfo.pickupPerson.pickUpByName1 }}<br />
                  <!-- Phone:
                  {{
                    orderInfo.pickupPerson.pickUpByPhone1
                      ? maskPhoneNumber(orderInfo.pickupPerson.pickUpByPhone1)
                      : 'NA'
                  }} -->
                  Email:
                  {{
                    orderInfo.pickupPerson.pickUpByEmail1
                      ? maskPhoneNumber(orderInfo.pickupPerson.pickUpByEmail1)
                      : 'NA'
                  }}
                </p>
              </div>
            </div>
            <div v-if="orderInfo.orderPaymentDetail">
              <div class="orderReturnMobinfo">
                <h5>Payment Method</h5>
                <p>
                  Credit Card<br />
                  <span :class="`paymentIcon paymentIcon-left`">
                    <img
                      :src="`images/payment/${
                        getCardType(orderInfo.orderPaymentDetail.cardType).logo
                      }`"
                      :title="
                        getCardType(orderInfo.orderPaymentDetail.cardType).title
                      "
                    />
                  </span>
                  <!-- <SvgIcon size="24px" icon="card" class="q-mr-sm" /> -->
                  xxxx-xxxx-xxxx-{{
                    orderInfo.orderPaymentDetail.cardLast4Digits
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="row orderReturnAction">
            <div
              class="col-12 col-sm-4 offset-sm-8 col-md-3 offset-md-9 text-right q-mt-lg"
            >
              <q-btn
                unelevated
                :rounded="!isMobile"
                color="primary"
                label="Submit Request"
                type="submit"
                class="q-py-sm full-width"
                :disable="this.reasonArr.length == 0 || !checkAll"
              />
            </div>
          </div>
        </q-form>
        <!-- Order Return 1 -->
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { maskPhoneNumber, getCardType } from 'src/utils'
import ReturnOrderCartButton from 'components/ReturnOrderCartButton'

export default {
  name: 'PageOrderStartReturn',
  /* preFetch({ store, currentRoute, redirect }) {
    let { orderId } = currentRoute.params,
      promise = store
        .dispatch('orderReturn/viewOrderReturnDetail', orderId)
        .then((response) => {
          if (!response)
            return redirect({
              name: 'Orders',
            })
          if (!response.isReturnPossible)
            return redirect({
              name: 'Orders',
            })
        })

    if (process.env.SERVER) return promise
  }, */
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  meta() {
    return {
      title: 'Order Start Return',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      alertShow: 0,
      selection: [],
      reason: {},
      otherReason: {},
      isCheckAll: false,
      reasonArr: [],
    }
  },
  watch: {
    startReturnAlert() {
      this.alertShow = 10
    },
  },
  methods: {
    maskPhoneNumber,
    getCardType: getCardType,
    myRules(val, item) {
      if (!this.findObject(item) && !val) return 'Please select'
    },
    postReason() {
      if (this.reasonArr.length > 0) {
        this.$store.dispatch('orderReturn/postReturn', this.reasonArr)
      }
    },
    checkAll() {
      let selection = []
      if (this.isCheckAll) {
        this.orderInfo.orderLineItemsForReturn.forEach((element) => {
          if (element.isReturnable) {
            selection.push(element)
          }
        })
      } else {
        // this.returnReasonArr()
        this.reasonArr = []
        this.reason = {}
        this.otherReason = {}
      }
      this.selection = selection
      this.rejectArr()
    },
    returnReasonArr() {
      let matchedReasonArr = []
      const fixedReasonArr = this.reasonArr
      let reasonArr = []
      this.selection.map((item) => {
        fixedReasonArr.findIndex((o) => {
          if (o.orderLineItemID === item.orderLineItemID) {
            reasonArr.push(o)
          }
        })
        matchedReasonArr = reasonArr
      })
      this.reasonArr = matchedReasonArr
    },
    updateCheckall(item) {
      this.isCheckAll =
        this.selection.length == this.orderInfo.orderLineItemsForReturn.length
          ? true
          : this.selection.length === 0
          ? false
          : null

      if (this.selection.length > 0) {
        this.rejectArr()
      }

      if (this.selection.length !== this.reasonArr.length) {
        this.returnReasonArr()
      }

      if (item.orderLineItemID in this.reason) {
        delete this.reason[item.orderLineItemID]
      }
    },
    updateReturnItemQty(updatedItem) {
      this.reasonArr.filter((item) => {
        if (item.orderLineItemID === updatedItem.orderLineItemID) {
          item.qty = Number(updatedItem.qty)
        }
      })
    },
    otherReasonChange(orderLineItemID) {
      this.reasonArr.filter((item) => {
        if (item.orderLineItemID === orderLineItemID) {
          item.description = this.otherReason[orderLineItemID]
        }
      })
    },
    rejectArr() {
      this.selection.map((element) => {
        let reasonPostObj = {}
        reasonPostObj.orderLineItemID = element.orderLineItemID
        if (this.reason[element.orderLineItemID] === 'Other') {
          reasonPostObj.description = this.otherReason[element.orderLineItemID]
        } else {
          reasonPostObj.description = null
        }
        reasonPostObj.qty = Number(element.qtyAvailableforReturn)
        reasonPostObj.reason = this.returnReason.findIndex(
          (o) => o.returnReasonTitle === this.reason[element.orderLineItemID]
        )
        if (
          this.reasonArr.findIndex(
            (o) => o.orderLineItemID === reasonPostObj.orderLineItemID
          ) == -1
        ) {
          this.reasonArr.push(reasonPostObj)
        }
      })
    },
    selectChange(orderLineItemID, itemObj) {
      this.reasonArr.filter((item) => {
        if (item.orderLineItemID === orderLineItemID) {
          item.reason = this.returnReason.findIndex(
            (o) => o.returnReasonTitle === this.reason[orderLineItemID]
          )
          if (!item.qty) {
            item.qty = Number(itemObj.qtyAvailableforReturn)
          }
          item.description = null
          delete this.otherReason[orderLineItemID]
        }
      })
    },
    findObject(item) {
      return this.selection.filter(
        (x) => x.orderLineItemID === item.orderLineItemID
      ).length > 0
        ? false
        : true
    },
  },
  computed: {
    ...mapGetters('orderReturn', ['returnOrderInfoById', 'returnReason']),
    ...mapGetters('order', ['alertData']),
    startReturnAlert() {
      return this.alertData('startReturnAlert')
    },
    orderInfo() {
      return this.returnOrderInfoById(this.orderId)
    },
  },
  created() {
    this.$store.dispatch('orderReturn/returnReasons')
  },
  destroyed() {
    if (this.startReturnAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'startReturnAlert',
        alertData: null,
      })
  },
  components: {
    ReturnOrderCartButton,
  },
  mounted() {
    // prefetch code
    let { orderId } = this.$route.params
    this.$store
      .dispatch('orderReturn/viewOrderReturnDetail', orderId)
      .then((response) => {
        if (!response)
          return this.$router.replace({
            name: 'Orders',
          })
        if (!response.isReturnPossible)
          return this.$router.replace({
            name: 'Orders',
          })
      })
    // prefetch code
  },
}
</script>
<style lang="scss">
.orderItemDetail {
  .OrdetItemImg {
    flex: 0 0 42px;
    margin-right: 8px;
  }
}
.orderStartReturn {
  @media (max-width: 767px) {
    padding-bottom: 90px;
    .orderReturnAction {
      background: #fff;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      .q-mt-lg {
        margin-top: 12px;
        margin-bottom: 12px;
        padding: 0 12px;
      }
    }
  }
}
.orderTables {
  // min-height: 360px;
  @media (max-width: 767px) {
    min-height: auto;
  }
  div.orderItem {
    position: relative;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    // flex-wrap: nowrap;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 16px;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    &.orderItemhd {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.25);
      h5,
      .OrdetItemIssue,
      .OrdetItemQty,
      .OrdetItemTotal {
        font-size: 18px !important;
        font-weight: bold !important;
        color: rgba(49, 65, 84, 0.5) !important;
        @media (max-width: 1023px) {
          font-size: 16px !important;
        }
      }
      .checkbox {
        flex: 0 0 45px;
        text-align: center;
        font-size: 24px;
        line-height: 35px;
      }
      .OrdetItemIssue,
      .OrdetItemQty {
        padding: 10px;
      }
      @media (max-width: 767px) {
        .OrdetItemIssue,
        .OrdetItemQty {
          display: none;
        }
        .OrdetItemTotal {
          text-align: right;
        }
      }
    }
    .orderItemDetail {
      flex: 10000 0 0%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .icon,
      .checkbox {
        flex: 0 0 45px;
        text-align: center;
        font-size: 24px;
        line-height: 35px;
      }
      h5 {
        margin: 0;
        padding: 10px 0;
        font-size: 16px;
        line-height: normal;
        font-weight: normal;
        color: $tertiary;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    .OrdetItemIssue {
      min-width: 220px;
      padding: 0px 10px;
      color: #888888;
      .q-field {
        width: 180px;
        padding-bottom: 0;
      }
      .q-field__control-container {
        font-size: 13px;
      }
      @media (max-width: 767px) {
        order: 4;
        padding-bottom: 16px;
        padding-left: 45px;
        min-width: 100%;
        .q-field {
          width: 100%;
          padding-bottom: 20px;
          &.q-select {
            width: 200px;
            padding-bottom: 0;
          }
        }
        .q-field__bottom {
          padding-top: 2px;
        }
      }
    }
    div.OrdetItemQty {
      min-width: 220px;
      padding: 0;
      color: #888888;
      .quantityBox {
        width: 90px !important;
        margin-right: 8px !important;
        @media (max-width: 767px) {
          .error {
            &.top {
              top: auto;
              bottom: -11px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        order: 6;
        /*padding-top: 10px;*/
        padding-left: 45px;
        min-width: 100%;
      }
    }
    .OrdetItemTotal {
      min-width: 80px;
      padding: 10px 0;
      font-weight: bold;
      color: $tertiary;
      @media (max-width: 767px) {
        text-align: right;
      }
    }
    .OrdetItemAction {
      // position: absolute;
      position: relative;
      right: 0;
      bottom: 0;
      // height: 100px;
      height: auto;
      min-width: 510px;
      max-width: 620px;
      margin-left: auto;
      margin-top: 10px;
      @media (max-width: 767px) {
        min-width: auto;
        max-width: 100%;
        left: 46px;
        width: calc(100% - 46px);
        margin-left: 0;
        order: 5;
        padding-bottom: 0;
      }
    }
    &.orderItemTextarea {
      // padding-bottom: 120px;
      padding-bottom: 0px;
      .form-action {
        .q-field__control {
          // height: 85px;
        }
      }
    }
  }
}
/* Order Summary Info */
.orderSummaryInfo {
  margin-top: 20px;
  background: #fafafa;
  border: 1px solid #cccccc;
  padding: 20px;
  border-radius: 5px;
  .o-1 {
    order: 1;
  }
  .o-2 {
    order: 2;
  }
  .o-3 {
    order: 3;
  }
  .o-4 {
    order: 4;
  }
  @media (min-width: 600px) {
    .o-2 {
      order: 1;
    }
    .o-1 {
      order: 2;
    }
  }
  @media (min-width: 1024px) {
    .o-1 {
      order: 5;
      margin-left: auto;
    }
  }
  h5 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: $tertiary;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #888888;
    .default {
      background: #91bf5b;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 12px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  @media (max-width: 767px) {
    padding: 16px 8px 16px;
    border: none;
    border-top: 1px solid #cccccc;
    background: none;
    @include border-radius(0);
    .row {
      justify-content: flex-end;
    }
    .orderSummaryITotal {
      li {
        padding: 2px 0;
      }
    }
  }
}
ul.orderSummaryITotal {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 6px 0;
    font-size: 16px;
    font-weight: normal;
    color: #888888;
    position: relative;
    span {
      font-weight: bold;
      width: 90px;
      position: absolute;
      right: 0;
      top: 6px;
      text-align: right;
    }
    &.total {
      padding: 10px 0 5px;
      font-weight: 700;
      color: #314154;
      span {
        top: 10px;
        font-weight: 700;
      }
    }
  }
}
/* Order Summary Info */
/* Start Return Info */
.startReturnInfo {
  margin-top: 30px;
  padding: 20px 25px;
  background: #fff;
  color: #888888;
  border: 1px solid $primary;
  border: 1px solid var(--q-color-primary) !important;
  border-radius: 8px;
  h3 {
    margin: 0;
    padding: 10px 0;
    font-size: 24px;
    line-height: normal;
    color: $primary;
    color: var(--q-color-primary) !important;
  }
  p {
    margin: 0;
    padding: 10px 0;
    font-size: 16px;
  }
}
/* Start Return Info */
/* Info for Mobile */
.orderReturnMobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6px;
  margin-left: -6px;
  margin-right: -6px;
  > div {
    flex: 1 0 50%;
    padding: 6px;
    width: 50%;
  }
  .orderReturnMobinfo {
    padding: 8px 12px;
    background: #fff;
    border: 1px solid #cccccc;
    height: 100%;
    border-radius: 5px;
  }
  h5 {
    margin: 0 0 4px;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: $tertiary;
  }
  p {
    margin: 0;
    font-size: 13px;
    line-height: 17px;
    color: #888888;
    .default {
      background: #91bf5b;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 12px;
    }
  }
}
/* Info for Mobile */
</style>
